/*!
 * Warning: estos componentes ya no se deben utilizar, pero los seguimos cargando para que el worker de Cloudflare
 * los pueda utilizar si el navegador no soporta alguna de estas features: WebP, lazy-load images o lazy-load iframes.
 * Si el navegador soporta lazy-load iframes estamos asumiendo que soporta todo lo demas
 */

class WebpSupport extends HTMLImageElement {
  constructor (width, height) {
    super(width, height)
    this.decoding = 'async'
    this.addEventListener('error', function () {
      if (this.src.includes('.webp')) {
        new webpHero.WebpMachine().polyfillImage(this)
      }
    })
  }
}

class LazyloadImage extends WebpSupport {
  connectedCallback () {
    new IntersectionObserver(
      (entries, observer) => {
        if (entries.length > 0 && entries[0].intersectionRatio > 0) {
          this.onVisible(observer)
        }
      }, { rootMargin: '12px' }
    ).observe(this)
  }

  onVisible (observer) {
    this.src = this.dataset.src
    delete this.dataset.src
    observer.unobserve(this)
  }
}

class LazyloadIframe extends HTMLIFrameElement {
  connectedCallback () {
    new IntersectionObserver(
      (entries, observer) => {
        if (entries.length > 0 && entries[0].intersectionRatio > 0) {
          this.onVisible(observer)
        }
      }, { rootMargin: '12px' }
    ).observe(this)
  }

  onVisible (observer) {
    this.src = this.dataset.src
    delete this.dataset.src
    observer.unobserve(this)
  }
}

customElements.define('webp-support', WebpSupport, { extends: 'img' })
customElements.define('lazyload-image', LazyloadImage, { extends: 'img' })
customElements.define('lazyload-iframe', LazyloadIframe, { extends: 'iframe' })
